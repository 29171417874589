import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Interweave } from "interweave";
import React from "react";

const HowItWorks = ({ isLinerPage }) => {
  const data = useStaticQuery(graphql`
    query HowItWorksQuery {
      wpPage(id: { eq: "cG9zdDoxMjA=" }) {
        title
        howItWorks {
          step {
            stepImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            stepTitle
            stepDescription
          }
          linerStep {
            stepImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            stepTitle
            stepDescription
          }
        }
      }
    }
  `);

  const stepsToUse = isLinerPage
    ? data.wpPage.howItWorks.linerStep
    : data.wpPage.howItWorks.step ?? [];

  return (
    <>
      <div className="">
        <h2 className="my-8 lg:text-4xl font-bold">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
          {stepsToUse.map((step, index) => {
            const imageData = getImage(step.stepImage.localFile);
            return (
              <div
                key={`step-${index}`}
                className="bg-grey-lighter rounded-lg p-4 lg:p-6"
              >
                <GatsbyImage
                  alt={step.stepImage.altText || "Step image"}
                  image={imageData}
                  className={`rounded w-full object-cover h-48 mb-3`}
                />
                <h4 className="text-lg lg:text-xl font-bold">
                  <span
                    className={`text-${process.env.THEME_COLOR_PRIMARY}-darker mr-2`}
                  >
                    Step {index + 1}.
                  </span>{" "}
                  {step.stepTitle}
                </h4>
                <Interweave
                  content={step.stepDescription}
                  className="leading-loose text-grey-darker"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
