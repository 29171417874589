import axios from "axios";
import classNames from "classnames";
import { Field, Form, Formik, useField } from "formik";
import { Link } from "gatsby";
import { usePostHog } from "posthog-js/react";
import React, { useContext, useMemo, useState } from "react";
import {
  BiCheckCircle,
  BiCircle,
  BiMinus,
  BiPlus,
  BiSolidCheckCircle,
} from "react-icons/bi";
import { FaRegSun } from "react-icons/fa";
import { FaCartFlatbed, FaRegEnvelope } from "react-icons/fa6";
import { FiAlertTriangle } from "react-icons/fi";
import { MdArrowBack, MdArrowForward, MdOutlineComment } from "react-icons/md";
import * as Yup from "yup";
import CartContext from "../../context/cart-context";
import { extendedZipCodes, zipCodes } from "../../data/zip-codes";
import useRushOrderProduct from "../../hooks/useRushOrderProduct";
import { formatPrice, Price } from "../../utilities/price";
import { styles } from "../../utilities/styles";
import PhoneInputField from "../formik/PhoneInputField";
import Modal from "./modal";
import QuoteSummaryBilling from "./quote-summary-billing";
import QuoteSummaryCheckout from "./quote-summary-checkout";
import QuoteSummaryInstallation from "./quote-summary-installation";

const Steps = ({ dueNow = "100" }) => {
  const steps = [
    {
      heading: "Due When Order is Placed",
      content: `<div class='text-xl font-semibold text-${process.env.THEME_COLOR_PRIMARY}-dark'>${dueNow}</div><div class="text-grey mt-2 text-sm">* Place a ${dueNow} deposit; refundable until measurement</div>`,
    },
    {
      heading: "We Measure",
      content: `<div>We come measure your wells for your custom-build covers.</div><div class="text-grey mt-2 text-sm">* We collect half the remaining balance</div>`,
    },
    {
      heading: "We build Custom Cover",
      content: "We return to install your custom build covers & accessories.",
    },
    {
      heading: "Custom Installation",
      content: `We return to install your custom build covers & accessories. <div class="text-grey mt-2 text-sm">*We collect remaining balance</div>`,
    },
  ];

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 my-4">
      {steps.map((step, index) => {
        return (
          <div
            className="p-4 border border-grey-light rounded-lg"
            key={`process-${index}`}
          >
            <div
              className={`font-bold mb-2 text-sm uppercase text-${process.env.THEME_COLOR_PRIMARY}-dark`}
            >
              Step {index + 1}
            </div>
            <h4 className="mb-2 font-bold">{step.heading}</h4>
            <div
              className="text-grey-darker"
              dangerouslySetInnerHTML={{
                __html: step.content,
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const NameEmail = ({ cart, totals }) => {
  const posthog = usePostHog();

  const [submitted, setSubmitted] = useState(false);

  const swiftUserId = useMemo(() => {
    if (typeof window === "undefined") {
      return null;
    }
    return localStorage.getItem("swiftUserId");
  }, []);

  if (submitted) {
    return (
      <div className="flex items-center justify-center text-lg h-48">
        We have sent you a link to your custom quote to your email. Thank you!
      </div>
    );
  }

  return (
    <div className="prose w-full max-w-5xl">
      <h2 className="mb-3">Please enter your name and email</h2>
      <p>You'll receive a link to your custom quote.</p>
      <Formik
        initialValues={{
          name: "",
          email: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          posthog.capture("quote-builder-send-quote", {
            ...values,
            source: "quote",
            type: "email",
            cart,
            swiftUserId,
          });

          axios
            .post(
              "/.netlify/functions/quote-builder-send",
              {
                ...values,
                source: "quote",
                type: "email",
                cart,
                totals,
                swiftUserId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              if (response.data?.success) {
                setSubmitted(true);
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min("4", "Must be at least 4 characters")
            .required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
        })}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div
                className={classNames(
                  "flex flex-wrap items-end gap-x-4",
                  isSubmitting && "opacity-50"
                )}
              >
                <div className="grid w-full lg:w-auto">
                  <label className="tracking-wide" htmlFor="name">
                    Name*
                  </label>
                  <Field
                    className={classNames(styles.textInput, "min-w-[220px]")}
                    id="name"
                    name="name"
                  />
                  <ErrorMessage name="name" />
                </div>
                <div className="grid w-full lg:w-auto">
                  <label className="tracking-wide" htmlFor="email">
                    Email*
                  </label>
                  <Field
                    className={classNames(styles.textInput, "min-w-[280px]")}
                    name="email"
                    id="email"
                    type="email"
                  />
                  <ErrorMessage name="email" />
                </div>
                <div>
                  <button
                    type="submit"
                    className={classNames(
                      styles.button.default,
                      "text-base px-6 py-1 rounded inline-block tracking-wide no-underline shadow transition-colors"
                    )}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <HeightDiv />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const NamePhone = ({ cart }) => {
  const posthog = usePostHog();

  const [submitted, setSubmitted] = useState(false);

  const swiftUserId = useMemo(() => {
    if (typeof window === "undefined") {
      return null;
    }
    return localStorage.getItem("swiftUserId");
  }, []);

  if (submitted) {
    return (
      <div className="flex items-center justify-center text-lg h-48">
        We have sent you a link to your custom quote. Thank you!
      </div>
    );
  }

  return (
    <div className="prose w-full max-w-5xl">
      <h2 className="mb-3">Please enter your name and phone number</h2>
      <p>You'll receive a link to your custom quote.</p>
      <Formik
        initialValues={{
          name: "",
          phone: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          posthog.capture("quote-builder-send-quote", {
            ...values,
            source: "quote",
            type: "sms",
            cart,
            swiftUserId,
          });

          axios
            .post(
              "/.netlify/functions/quote-builder-send",
              {
                ...values,
                source: "quote",
                type: "sms",
                cart,
                swiftUserId,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              if (response.data?.success) {
                setSubmitted(true);
              }
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .min("4", "Must be at least 4 characters")
            .required("Required"),
          phone: Yup.string()
            .min("12", "Invalid phone number format")
            .max("12", "Invalid phone number format")
            .required("Required"),
        })}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div
                className={classNames(
                  "flex flex-wrap items-end gap-x-4",
                  isSubmitting && "opacity-50"
                )}
              >
                <div className="grid w-full lg:w-auto">
                  <label className="tracking-wide" htmlFor="name">
                    Name*
                  </label>
                  <Field className={styles.textInput} id="name" name="name" />
                  <ErrorMessage name="name" />
                </div>
                <div className="grid w-full lg:w-auto">
                  <label className="tracking-wide" htmlFor="phone">
                    Phone*
                  </label>
                  <Field
                    className={styles.textInput}
                    component={PhoneInputField}
                    name="phone"
                    id="phone"
                  />
                  <ErrorMessage name="phone" />
                </div>
                <div>
                  <button
                    type="submit"
                    className={classNames(
                      styles.button.default,
                      "text-base px-6 py-1 rounded inline-block tracking-wide no-underline shadow transition-colors"
                    )}
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                  <HeightDiv />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const ServiceArea = ({ emitServiceAreaStatus }) => {
  const { checkoutInfo, setCheckoutInfo } = useContext(CartContext);
  const [serviceAreaStatus, setServiceAreaStatus] = useState("");

  return (
    <Formik
      initialValues={{
        shippingZip: checkoutInfo.shippingZip || "",
      }}
      onSubmit={(values) => {
        console.info("zip", values.shippingZip);
      }}
      validateOnMount={!!checkoutInfo.shippingZip}
      validate={(values) => {
        let errors = {};
        let status = "inside";

        if (!values.shippingZip) {
          errors.shippingZip = "required";
          status = "required";
        } else if (values.shippingZip.length !== 5) {
          errors.shippingZip = "Please enter a valid zip code";
          status = "Please enter a valid zip code";
        } else {
          if (
            zipCodes.indexOf(parseInt(values.shippingZip)) < 0 &&
            extendedZipCodes.indexOf(parseInt(values.shippingZip)) < 0 &&
            values.shippingZip.length === 5
          ) {
            status = "outside";
          } else if (
            extendedZipCodes.indexOf(parseInt(values.shippingZip)) > -1
          ) {
            status = "extended";
          } else {
            status = "inside";
          }
        }

        setServiceAreaStatus(status);
        emitServiceAreaStatus(status);
        setCheckoutInfo({
          ...checkoutInfo,
          ...values,
        });

        return errors;
      }}
    >
      {({ errors }) => {
        return (
          <Form>
            <div className="lg:flex flex-wrap gap-3 justify-start mb-6 lg:mb-0">
              <div>
                <Field
                  autoComplete="postal-code"
                  type="text"
                  id="shippingZip"
                  name="shippingZip"
                  placeholder="Enter your zip code"
                  className="appearance-none border border-grey-light rounded py-2 px-3  mb-3 lg:mb-0 text-grey-darker leading-tight focus:outline-none focus:ring"
                />
              </div>

              {!errors.shippingZip && (
                <>
                  {serviceAreaStatus === "outside" && (
                    <div className="bg-orange-lightest text-orange-dark flex items-center gap-3 px-3 py-2">
                      <FiAlertTriangle size={20} />{" "}
                      <div>
                        <strong>Outside service areas</strong> <br />
                        Call our office at{" "}
                        <a
                          tel={`${process.env.PHONE_NUMBER}`}
                          className="underline"
                        >
                          {process.env.PHONE_NUMBER}
                        </a>{" "}
                        for more details and options. <br />
                        <Link to="/service-areas/" className="underline">
                          See areas here
                        </Link>
                      </div>
                    </div>
                  )}
                  {serviceAreaStatus === "extended" && (
                    <div className="bg-green-lightest text-green-dark flex items-center gap-3 px-3 py-2">
                      <BiCheckCircle size={20} />
                      <div>
                        Within extended service area ($140 fee added to order){" "}
                        <br />
                        <Link
                          to="/service-areas/"
                          className="underline whitespace-nowrap"
                        >
                          See areas here
                        </Link>
                      </div>
                    </div>
                  )}
                  {serviceAreaStatus === "inside" && (
                    <div className="bg-green-lightest text-green-dark flex items-center gap-3 px-3 py-2">
                      <BiCheckCircle size={20} /> Within service area
                    </div>
                  )}
                </>
              )}
              {errors.shippingZip && (
                <div className="bg-orange-lightest text-orange-dark flex items-center gap-3 px-3 py-2">
                  <FiAlertTriangle size={20} /> <div>{errors.shippingZip}</div>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const QuoteSummary = ({
  items = [],
  emitAddProduct,
  emitRemoveProduct,
  emitClearItems,
}) => {
  const { checkoutInfo } = useContext(CartContext);
  const [serviceAreaStatus, setServiceAreaStatus] = useState("");
  const [rushOrder, setRushOrder] = useState(false);
  const [startOrderOpen, setStartOrderOpen] = useState(false);

  const rushOrderProduct = useRushOrderProduct();

  const rushOrderProductPrice =
    rushOrderProduct?.node?.product?.salePrice ||
    rushOrderProduct?.node?.product?.basePrice ||
    0;

  const isSwiftUser = useMemo(() => {
    if (typeof window === "undefined") {
      return null;
    }
    return !!localStorage.getItem("swiftUserId");
  }, []);

  const getItemPrice = (item) => {
    if (
      (item.category[0] === "liners" || item.category === "liners") &&
      isSwiftUser
    ) {
      return (item.salePrice || item.basePrice) - 10;
    }
    return item.salePrice || item.basePrice;
  };

  const freeLaddersOrLocksDiscount = useMemo(() => {
    const countOfCovers = items.reduce((acc, item) => {
      if (item.category[0] === "covers" || item.category === "covers") {
        return acc + (item.quantity ?? 0);
      }
      return acc;
    }, 0);

    const accessoriesInCart = items.filter(
      (item) =>
        item.category[0] === "accessories" || item.category === "accessories"
    );

    const laddersInCart = accessoriesInCart.filter(
      (item) => item.id?.includes("ladder") || item.sku?.includes("ladder")
    );
    const locksInCart = accessoriesInCart.filter(
      (item) => item.id?.includes("lock") || item.sku?.includes("lock")
    );

    const laddersInCartCount = laddersInCart.reduce(
      (acc, item) => acc + (item.quantity ?? 0),
      0
    );
    const locksInCartCount = locksInCart.reduce(
      (acc, item) => acc + (item.quantity ?? 0),
      0
    );

    const freeLaddersCount =
      isSwiftUser && laddersInCartCount
        ? Math.min(laddersInCartCount, countOfCovers)
        : 0;

    const freeLocksCount =
      isSwiftUser && locksInCartCount
        ? Math.min(locksInCartCount, countOfCovers - freeLaddersCount)
        : 0;

    const laddersPrice =
      laddersInCart?.at(0)?.salePrice || laddersInCart?.at(0)?.basePrice || 0;
    const locksPrice =
      locksInCart?.at(0)?.salePrice || locksInCart?.at(0)?.basePrice || 0;

    const freeLaddersOrLocksDiscount = isSwiftUser
      ? (freeLaddersCount * laddersPrice + freeLocksCount * locksPrice) * -1
      : 0;

    return freeLaddersOrLocksDiscount;
  }, [items, isSwiftUser]);

  const hasLiners = items.find((item) => {
    return item.category.includes("liners");
  });

  const linersDiscount = useMemo(() => {
    if (!isSwiftUser) {
      return 0;
    }
    const countOfLiners = items.reduce((acc, item) => {
      if (item.category[0] === "liners" || item.category === "liners") {
        return acc + (item.quantity ?? 0);
      }
      return acc;
    }, 0);

    return countOfLiners * -10;
  }, [items, isSwiftUser]);

  const totals = useMemo(() => {
    const extendedArea = serviceAreaStatus === "extended" ? 140 : 0;
    const rushOrderFee = rushOrder ? rushOrderProductPrice : 0;
    const taxableItemsTotal = items.reduce(
      (accumulator, item) => accumulator + getItemPrice(item) * item.quantity,
      0
    );
    const prePromotionTotal = taxableItemsTotal - linersDiscount;
    const subtotal = taxableItemsTotal + (freeLaddersOrLocksDiscount || 0);

    const tax = +(taxableItemsTotal * process.env.TAX_RATE).toFixed(2) || 0;
    const total = subtotal + extendedArea + rushOrderFee + tax;
    const dueNow = isSwiftUser ? Math.floor((total / 2) * 100) / 100 : 100;

    return {
      prePromotionTotal: formatPrice(prePromotionTotal),
      countItems: items.length,
      dueAtInstall: isSwiftUser
        ? total - dueNow
        : formatPrice((total - 100) / 2),
      dueAtMeasure: isSwiftUser ? 0 : formatPrice((total - 100) / 2),
      dueNow: formatPrice(dueNow),
      extendedArea: extendedArea ? formatPrice(extendedArea) : 0,
      rushOrderFee: rushOrderFee ? formatPrice(rushOrderFee) : 0,
      subtotal: formatPrice(subtotal),
      tax: formatPrice(tax),
      total: formatPrice(total),
      freeLaddersOrLocksDiscount: freeLaddersOrLocksDiscount
        ? formatPrice(freeLaddersOrLocksDiscount)
        : 0,
      linerDiscount: linersDiscount ? formatPrice(linersDiscount) : 0,
      discount: freeLaddersOrLocksDiscount || 0,
    };
  }, [
    items,
    serviceAreaStatus,
    rushOrder,
    rushOrderProductPrice,
    isSwiftUser,
    linersDiscount,
    freeLaddersOrLocksDiscount,
  ]);

  const tabs = useMemo(() => {
    return [
      {
        index: 0,
        label: "Customer Information",
        id: "checkout",
        completed:
          checkoutInfo.firstName &&
          checkoutInfo.lastName &&
          checkoutInfo.email &&
          checkoutInfo.phone,
      },
      {
        index: 1,
        label: "Installation Information",
        id: "installation",
        completed:
          checkoutInfo.shippingAddress &&
          checkoutInfo.shippingCity &&
          checkoutInfo.shippingState,
      },
      {
        index: 2,
        label: "Billing Information",
        id: "billing",
        completed: false,
      },
    ];
  }, [checkoutInfo]);

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activeForm, setActiveForm] = useState(null);

  const handleTabClick = (tab) => {
    if (activeTab.id === "billing") {
      return setActiveTab(tab);
    }

    if (activeForm.current) {
      activeForm.current.handleSubmit();
      if (activeForm.current.isValid) {
        setActiveTab(tab);
      }
    }
  };

  const handleGoToTab = (id) => {
    if (id) {
      setActiveTab(tabs.find((tab) => tab.id === id));
    }
  };

  const handleFormImport = (form) => {
    setActiveForm(form);
  };

  const handleFormSubmit = () => {
    if (activeForm.current) {
      activeForm.current.validateForm();
      activeForm.current.handleSubmit();
      if (activeForm.current.isValid) {
        setActiveTab(tabs[activeTab.index + 1]);
      }
    }
  };

  const removeProduct = (data) => {
    emitRemoveProduct(data.id, data.category[0]);
  };

  const addProduct = (product, quantity) => {
    // if category is an array, use the first item, otherwise use the category
    // we have to check type because sometimes the category is an array and sometimes it's a string
    let category = "";
    if (typeof product.category === "object") {
      category = product.category[0];
    } else {
      category = product.category;
    }

    emitAddProduct({
      id: product.id,
      category: category,
      qty: Number(quantity),
      override: true,
    });
  };

  const handleServiceAreaChange = (data) => {
    setServiceAreaStatus(data);
  };

  const handleClearItems = () => {
    emitClearItems();
  };

  return (
    <>
      <div className="border border-grey rounded-lg p-4 lg:p-12 mb-10">
        <h2 className="lg:text-3xl mb-4 flex-1">Quote Summary</h2>

        <div className="hidden md:flex justify-between font-semibold mb-2">
          <div className="text-left">Product</div>
          <div className="flex justify-between text-center md:w-2/5 flex-0">
            <div className="w-36">Quantity</div>
            <div className="w-36">Rate</div>
            <div className="w-36">Amount</div>
            <div className="text-right">Actions</div>
          </div>
        </div>

        {items.map((item) => {
          return (
            <div
              key={item.id}
              className="flex flex-wrap justify-between p-4 bg-grey-lighter rounded mb-2"
            >
              <div className="flex items-center gap-3 mb-3 md:mb-0">
                <img
                  src={item.image}
                  alt={"Product"}
                  className={`rounded w-12 h-12 object-cover`}
                />

                {item.title}
              </div>
              <div className="flex flex-wrap lg:flex-nowrap items-center justify-between md:w-2/5 flex-0">
                <div className="flex items-center justify-center gap-2 w-36">
                  <button
                    disabled={item.quantity <= 0}
                    onClick={() => addProduct(item, item.quantity - 1)}
                    className="rounded-full bg-grey-light w-6 h-6 flex justify-center items-center"
                  >
                    <BiMinus />
                  </button>
                  <input
                    type="number"
                    className="text-grey-darker w-12 block text-center remove-number-spinner py-1 px-3 rounded border border-grey-light"
                    value={item.quantity}
                    onChange={(e) => {
                      addProduct(item, e.target.value);
                    }}
                  />
                  <button
                    onClick={() => addProduct(item, item.quantity + 1)}
                    className="rounded-full bg-grey-light w-6 h-6 flex justify-center items-center"
                  >
                    <BiPlus />
                  </button>
                </div>
                <div className="hidden lg:block bg-grey-lighter w-36 text-center">
                  <Price value={getItemPrice(item)} />
                </div>
                <div className="lg:w-36 text-center">
                  <Price
                    className="bg-grey-lighter"
                    value={getItemPrice(item) * item.quantity}
                  />
                </div>
                <div className="bg-grey-lighter w-12 text-right">
                  <button
                    className="text-lg p-2 inline-block"
                    onClick={() => removeProduct(item)}
                  >
                    &times;
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <div className="px-4 mb-10">
          {isSwiftUser && (
            <div className="flex items-center justify-between py-2">
              <span>Pre-Promotion Amount</span>
              <span>{totals.prePromotionTotal}</span>
            </div>
          )}
          {!!totals.linerDiscount && (
            <div className="flex items-center justify-between py-2 font-semibold bg-blue-lightest rounded p-4 -mx-4">
              <span>
                <FaRegSun className="inline-block mr-1 -translate-y-px" />
                On Door Liner Promotion
              </span>
              <span>{totals.linerDiscount}</span>
            </div>
          )}
          {!!totals.freeLaddersOrLocksDiscount && (
            <div className="flex items-center justify-between py-2 font-semibold bg-blue-lightest rounded p-4 -mx-4">
              <span>
                <FaRegSun className="inline-block mr-1 -translate-y-px" />
                On Door Free Ladders Or Locks Promotion
              </span>
              <span>{totals.freeLaddersOrLocksDiscount}</span>
            </div>
          )}
          <div className="flex items-center justify-between py-2">
            <span>Subtotal</span>
            <span>{totals.subtotal}</span>
          </div>
          <div className="flex items-center justify-between py-2">
            <span>Sales Tax</span>
            <span>{totals.tax}</span>
          </div>
          {!!totals.extendedArea && (
            <div className="flex items-center justify-between py-2">
              <span>Service Area Fee</span>
              <span>{totals.extendedArea}</span>
            </div>
          )}
          {!!totals.rushOrderFee && (
            <div className="flex items-center justify-between py-2">
              <span>Rush Order Fee</span>
              <span>{totals.rushOrderFee}</span>
            </div>
          )}
          <div className="flex items-center justify-between py-2">
            <span>Total</span>
            <span>{totals.total}</span>
          </div>
          <div className="flex items-center justify-between py-2">
            <strong>Due When Order is Placed</strong>

            <strong className={`text-${process.env.THEME_COLOR_PRIMARY}-dark`}>
              {totals.dueNow}
            </strong>
          </div>
        </div>

        <div className="flex flex-wrap items-start justify-end mb-10 gap-6">
          <div className="flex shrink-0 items-center justify-end gap-2">
            <Modal
              buttonText="Text Quote"
              buttonIcon={<MdOutlineComment size={20} />}
              buttonClasses="flex items-center gap-2 bg-grey-lighter rounded py-2 px-4"
            >
              <NamePhone cart={items} />
            </Modal>
            <Modal
              buttonText="Email Quote"
              buttonIcon={<FaRegEnvelope size={20} />}
              buttonClasses="flex items-center gap-2 bg-grey-lighter rounded py-2 px-4"
            >
              <NameEmail cart={items} totals={totals} />
            </Modal>
            <button
              className={`bg-${process.env.THEME_COLOR_PRIMARY}-dark text-white rounded py-2 px-4 flex items-center gap-2`}
              onClick={() => setStartOrderOpen(true)}
            >
              <FaCartFlatbed />
              <span>Order Now</span>
            </button>
          </div>
        </div>

        {startOrderOpen && (
          <div className="mb-10 py-4 px-6 bg-grey-lighter rounded-lg">
            <label htmlFor="shippingZip" className="block mb-2">
              Are you in our service area?
              <abbr className="required" title="Required">
                *
              </abbr>
            </label>
            <ServiceArea emitServiceAreaStatus={handleServiceAreaChange} />
          </div>
        )}

        {(serviceAreaStatus === "inside" ||
          serviceAreaStatus === "extended") && (
          <>
            <div className="flex items-center justify-between">
              <div>
                <h2 className="font-bold text-lg lg:text-xl">
                  How to Start My Order
                </h2>
                {!rushOrder && (
                  <div className="text-grey-dark text-sm">
                    1 week turnaround time -{" "}
                    <Modal
                      buttonText="Need it quicker?"
                      buttonClasses="underline"
                    >
                      <div>
                        <div className="flex gap-2 items-center">
                          <h3>Rush Order</h3>
                          <p>+${rushOrderProductPrice}</p>
                        </div>
                        <p className="mb-3">
                          Need you covers quicker? If you pay the rush fee, your
                          order receives priority in our entire process. This
                          means that the technician&apos;s visit for
                          measurements, as well as the production and
                          installation of your custom window well covers, will
                          be expedited. Our team will prioritize your project to
                          ensure that your covers are measured, built, and
                          installed faster than the standard timeline.
                        </p>
                        <button
                          className={classNames(
                            styles.button.default,
                            "py-2 px-3 rounded"
                          )}
                          onClick={() => setRushOrder(true)}
                        >
                          Add Rush Order
                        </button>
                      </div>
                    </Modal>
                  </div>
                )}
                {rushOrder && (
                  <button
                    className="underline"
                    onClick={() => setRushOrder(false)}
                  >
                    Remove Rush Order
                  </button>
                )}
              </div>
            </div>
            <Steps dueNow={totals.dueNow} />
            <h2 className="font-bold text-lg lg:text-xl mt-10 mb-4">
              Place My Deposit
            </h2>
            <div className="flex border-b border-grey-light mb-8">
              {activeTab.id !== "checkout" && (
                <button
                  onClick={() => {
                    if (activeTab.index > 0) {
                      setActiveTab(tabs[activeTab.index - 1]);
                    }
                  }}
                  className="flex items-center gap-2 p-3 lg:hidden"
                >
                  <MdArrowBack /> Prev
                </button>
              )}
              {tabs.map((tab) => {
                return (
                  <button
                    key={tab.id}
                    className={`px-4 py-4 border-b-2 items-center gap-2 ${
                      activeTab === tab
                        ? "bg-grey-lighter flex"
                        : "hidden md:flex"
                    }`}
                    style={{
                      borderColor: activeTab === tab ? "#000" : "transparent",
                    }}
                    disabled={activeTab === tab || !tab.completed}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab.completed && (
                      <BiSolidCheckCircle
                        size={20}
                        className={`text-${process.env.THEME_COLOR_PRIMARY}-dark`}
                      />
                    )}
                    {!tab.completed && <BiCircle size={20} />}
                    <span>{tab.label}</span>
                  </button>
                );
              })}
              {activeTab.id !== "billing" && (
                <button
                  onClick={() => {
                    if (activeTab.index < tabs.length) {
                      setActiveTab(tabs[activeTab.index + 1]);
                    }
                  }}
                  className="flex items-center gap-2 p-3 lg:hidden"
                >
                  Next <MdArrowForward />
                </button>
              )}
            </div>
            {activeTab.id === "checkout" && (
              <QuoteSummaryCheckout
                emitSubmit={handleFormSubmit}
                emitForm={handleFormImport}
              />
            )}
            {activeTab.id === "installation" && (
              <QuoteSummaryInstallation
                emitGoToTab={handleGoToTab}
                emitForm={handleFormImport}
                emitSubmit={handleFormSubmit}
                hasLiners={!!hasLiners}
              />
            )}
            {activeTab.id === "billing" && (
              <QuoteSummaryBilling
                emitGoToTab={handleGoToTab}
                emitSubmitted={handleClearItems}
                quoteItems={items}
                totals={totals}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

const HeightDiv = () => {
  return <div className="h-7"></div>;
};

const ErrorMessage = ({ name }) => {
  const [_input, meta, _helpers] = useField(name);

  if (!meta.dirty && !meta.touched) {
    return <HeightDiv />;
  }

  if (meta.error) {
    return <div className="text-red-dark">{meta.error}</div>;
  }

  return <HeightDiv />;
};

export default QuoteSummary;
