/** @type {import('tailwindcss').Config} */
const defaultTheme = require("tailwindcss/defaultTheme");

module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "./public/*.html",
    "./public/**/*.html",
  ],

  theme: {
    extend: {
      width: {
        20: "5rem",
      },
    },
    colors: {
      ...defaultTheme.colors,
      grey: {
        darkest: "#3A3A3A",
        darker: "#606f7b",
        dark: "#8795a1",
        DEFAULT: "#b8c2cc",
        light: "#dae1e7",
        lighter: "#f1f5f8",
        lightest: "#f8fafc",
      },

      white: "#ffffff",
      black: "#22292f",

      brown: "#723001",

      red: {
        darkest: "#3b0d0c",
        darker: "#621b18",
        dark: "#cc1f1a",
        DEFAULT: "#e3342f",
        light: "#ef5753",
        lighter: "#f9acaa",
        lightest: "#fcebea",
      },

      orange: {
        darkest: "#462a16",
        darker: "#613b1f",
        dark: "#de751f",
        DEFAULT: "#f6993f",
        light: "#faad63",
        lighter: "#fcd9b6",
        lightest: "#fff5eb",
      },

      yellow: {
        darkest: "#655101",
        darker: "#977902",
        dark: "#caa202",
        DEFAULT: "#fbc803",
        light: "#fdd73f",
        lighter: "#fee47c",
        lightest: "#fef0b8",
      },

      green: {
        darkest: "#13810c",
        darker: "#2f9423",
        dark: "#45a736",
        DEFAULT: "#5AB948",
        light: "#6ece5b",
        lighter: "#cbe5c5",
        lightest: "#ebf5e9",
      },

      teal: {
        darkest: "#0d3331",
        darker: "#20504f",
        dark: "#38a89d",
        DEFAULT: "#4dc0b5",
        light: "#64d5ca",
        lighter: "#a0f0ed",
        lightest: "#e8fffe",
      },

      blue: {
        darkest: "#12283a",
        darker: "#1c3d5a",
        dark: "#2779bd",
        DEFAULT: "#3490dc",
        light: "#6cb2eb",
        lighter: "#bcdefa",
        lightest: "#eff8ff",
      },

      indigo: {
        darkest: "#191e38",
        darker: "#2f365f",
        dark: "#5661b3",
        DEFAULT: "#6574cd",
        light: "#7886d7",
        lighter: "#b2b7ff",
        lightest: "#e6e8ff",
      },

      purple: {
        darkest: "#21183c",
        darker: "#382b5f",
        dark: "#794acf",
        DEFAULT: "#9561e2",
        light: "#a779e9",
        lighter: "#d6bbfc",
        lightest: "#f3ebff",
      },

      pink: {
        darkest: "#451225",
        darker: "#6f213f",
        dark: "#eb5286",
        DEFAULT: "#f66d9b",
        light: "#fa7ea8",
        lighter: "#ffbbca",
        lightest: "#ffebef",
      },
    },

    textColor: ({ theme }) => ({
      ...theme("colors"),
    }),

    backgroundColor: ({ theme }) => ({
      ...theme("colors"),
    }),

    fontFamily: {
      sans: [
        "Proxima Nova",
        "system-ui",
        "BlinkMacSystemFont",
        "-apple-system",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ],
      serif: [
        "Constantia",
        "Lucida Bright",
        "Lucidabright",
        "Lucida Serif",
        "Lucida",
        "DejaVu Serif",
        "Bitstream Vera Serif",
        "Liberation Serif",
        "Georgia",
        "serif",
      ],
      mono: [
        "Menlo",
        "Monaco",
        "Consolas",
        "Liberation Mono",
        "Courier New",
        "monospace",
      ],
    },

    screens: {
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
    },

    fontSize: {
      xs: ".85rem", // 12px
      sm: ".9rem", // 14px
      base: "1.125rem", // 18px
      lg: "1.25rem", // 20px
      xl: "1.5rem", // 24px
      "2xl": "1.875rem", // 30px
      "3xl": "2.25rem", // 36px
      "4xl": "3rem", // 48px
      "5xl": "3.5rem", // 48px
    },

    fontWeight: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
    },

    margin: {
      auto: "auto",
      px: "1px",
      0: "0",
      1: "0.25rem",
      2: "0.5rem",
      3: "0.75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      8: "2rem",
      10: "2.5rem",
      12: "3rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      32: "8rem",
      "-px": "-1px",
      "-0": "-0",
      "-1": "-0.25rem",
      "-2": "-0.5rem",
      "-3": "-0.75rem",
      "-4": "-1rem",
      "-5": "-1.25rem",
      "-6": "-1.5rem",
      "-8": "-2rem",
      "-10": "-2.5rem",
      "-12": "-3rem",
      "-16": "-4rem",
      "-20": "-5rem",
      "-24": "-6rem",
      "-32": "-8rem",
    },

    boxShadow: {
      DEFAULT: "0 2px 4px 0 rgba(0,0,0,0.10)",
      md: "0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)",
      lg: "0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)",
      inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
      outline: "0 0 0 3px rgba(52,144,220,0.5)",
      none: "none",
    },

    zIndex: {
      auto: "auto",
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
    },

    fill: {
      current: "currentColor",
    },

    stroke: {
      current: "currentColor",
    },

    letterSpacing: {
      tight: "-0.05em",
      normal: "0",
      wide: "0.05em",
    },

    lineHeight: {
      none: 1,
      tighter: 1.15,
      tight: 1.25,
      normal: 1.5,
      loose: 2,
    },

    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain",
    },

    borderWidth: {
      DEFAULT: "1px",
      0: "0",
      2: "2px",
      4: "4px",
      8: "8px",
    },

    borderColor: (theme) => ({
      ...theme("colors"),
      DEFAULT: theme("colors.gray.300", "currentColor"),
    }),

    borderRadius: {
      ...defaultTheme.borderRadius,
      none: "0",
      sm: "0.125rem",
      DEFAULT: "0.35rem",
      md: "0.375rem",
      lg: "0.8rem",
      full: "9999px",
      large: "12px",
    },

    minWidth: {
      0: "0",
      full: "100%",
    },

    minHeight: {
      0: "0",
      full: "100%",
      screen: "100vh",
    },

    maxWidth: {
      xs: "20rem",
      sm: "30rem",
      md: "40rem",
      lg: "50rem",
      xl: "60rem",
      "2xl": "70rem",
      "3xl": "80rem",
      "4xl": "90rem",
      "5xl": "100rem",
      full: "100%",
    },

    maxHeight: {
      lg: "700px",
      full: "100%",
      screen: "100vh",
    },

    padding: {
      px: "1px",
      0: "0",
      1: "0.25rem",
      2: "0.5rem",
      3: "0.75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      8: "2rem",
      10: "2.5rem",
      12: "3rem",
      16: "4rem",
      20: "5rem",
      24: "6rem",
      32: "8rem",
    },

    corePlugins: {
      objectFit: false,
      objectPosition: false,
    },

    container: {},
  },
  plugins: [require("@tailwindcss/typography")],
};
