import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import posthog from "posthog-js";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import CreditCardLogos from "../components/images/credit-card-logos";
import CartTotals from "../components/partials/cart-totals";
import CartTotalsMobile from "../components/partials/cart-totals-mobile";
import CartWidget from "../components/partials/cart-widget";
import Seo from "../components/seo";
import CartContext from "../context/cart-context";
import states from "../data/states";
import { SupDollarSign } from "../utilities/price";

export default class CheckoutStep3 extends React.Component {
  constructor(props) {
    super(props);
    this.toggleAddressType = this.toggleAddressType.bind(this);
    this.paymentCallback = this.paymentCallback.bind(this);
    this.timeoutCallback = this.timeoutCallback.bind(this);
    this.validationCallback = this.validationCallback.bind(this);

    const checkoutInfo =
      typeof window !== `undefined` &&
      JSON.parse(localStorage.getItem("cartValues"))
        ? JSON.parse(localStorage.getItem("cartValues")).checkoutInfo
        : {};

    this.state = {
      useDifferentAddress: false,
      shippingZip: checkoutInfo.shippingZip,
      shippingAddress: checkoutInfo.shippingAddress,
      billingFirstName: checkoutInfo.firstName,
      billingLastName: checkoutInfo.lastName,
      billingAddress: checkoutInfo.shippingAddress,
      billingAddress2: checkoutInfo.shippingAddress2,
      billingCity: checkoutInfo.shippingCity,
      billingState: checkoutInfo.shippingState,
      billingZip: checkoutInfo.shippingZip,
      billingNotes: "",
      discountCode: checkoutInfo.discountCode || "",
      useCardForRemainder: true,
      transactionDetails: {},
      ccnumberValid: false,
      ccexpValid: false,
      cvvValid: false,
      showDebugInfo: false,
      agreeToTerms: false,
      openTermsModal: false,
    };
  }

  componentDidMount() {
    if (!this.state.shippingZip) {
      navigate("/checkout/");
    }
    if (!this.state.shippingAddress) {
      navigate("/checkout/");
    }

    if (typeof window !== "undefined") {
      window.CollectJS.configure({
        callback: this.paymentCallback,
        paymentSelector: "#payButton",
        variant: "inline",
        googleFont: "Abel",
        invalidCss: {
          color: "#B40E3E",
        },
        validCss: {
          color: "#13AA73",
        },
        customCss: {
          "border-color": "#ffffff",
          "border-style": "solid",
        },
        focusCss: {
          "border-color": "#1AD18E",
          "border-style": "solid",
          "border-width": "3px",
        },
        fields: {
          ccnumber: {
            selector: "#ccnumber",
            title: "Card Number",
            placeholder: "0000 0000 0000 0000",
          },
          ccexp: {
            selector: "#ccexp",
            title: "Card Expiration",
            placeholder: "mm/yy",
          },
          cvv: {
            display: "show",
            selector: "#cvv",
            title: "CVV Code",
            placeholder: "***",
          },
        },
        validationCallback: this.validationCallback,
        timeoutDuration: 3000,
        timeoutCallback: this.timeoutCallback,
      });
    }
  }

  paymentCallback(response) {
    this.setState({ transactionDetails: response });
    if (response.token) {
      this.handleSubmit();
    } else {
      alert(
        "There is a problem with your card. Please check the info and try again"
      );
      this.setSubmitting(false);
    }
  }

  validationCallback(field, status, message) {
    const stateKey = `${field}Valid`;
    const newState = this.state;
    newState[stateKey] = status;
    this.setState(newState);
  }

  timeoutCallback() {
    alert(
      `We're having an issue processing your card. Please check your payment details. To place your order by phone call us at ${process.env.PHONE_NUMBER}`
    );
    this.setSubmitting(false);
  }

  toggleAddressType(e) {
    const checkoutInfo =
      typeof window !== `undefined`
        ? JSON.parse(localStorage.getItem("cartValues")).checkoutInfo
        : {};

    const useDifferentAddress = e.target.checked || false;

    this.setState({
      useDifferentAddress: useDifferentAddress,
    });

    if (useDifferentAddress) {
      this.setState({
        billingFirstName: "",
        billingLastName: "",
        billingAddress: "",
        billingAddress2: "",
        billingCity: "",
        billingState: "",
        billingZip: "",
      });
    } else {
      this.setState({
        billingFirstName: checkoutInfo.firstName,
        billingLastName: checkoutInfo.lastName,
        billingAddress: checkoutInfo.shippingAddress,
        billingAddress2: checkoutInfo.shippingAddress2,
        billingCity: checkoutInfo.shippingCity,
        billingState: checkoutInfo.shippingState,
        billingZip: checkoutInfo.shippingZip,
      });
    }
  }

  checkAddress() {
    if (!this.state.useDifferentAddress) {
      return true;
    }

    if (
      !this.state.billingFirstName ||
      !this.state.billingLastName ||
      !this.state.billingAddress ||
      !this.state.billingCity ||
      !this.state.billingState ||
      !this.state.billingZip
    ) {
      return false;
    }
    return true;
  }

  canSubmit(isSubmitting) {
    if (
      isSubmitting ||
      !this.checkAddress() ||
      !this.state.ccnumberValid ||
      !this.state.ccexpValid ||
      !this.state.cvvValid ||
      !this.state.agreeToTerms
    ) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <CartContext.Consumer>
        {(cart) => (
          <>
            <Seo title="Checkout Step 3" />
            <Helmet>
              <script
                src={`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`}
              ></script>
            </Helmet>
            <CheckoutEvent />
            <CartTotalsMobile />
            <div className="container px-4 mx-auto my-16">
              <div className="flex flex-wrap">
                <div className="w-full md:w-3/5 lg:pr-6">
                  <h1 className="font-xxl mb-8">Billing Information</h1>
                  <Formik
                    initialValues={{
                      billingFirstName: this.state.billingFirstName,
                      billingLastName: this.state.billingLastName,
                      billingAddress: this.state.billingAddress,
                      billingAddress2: this.state.billingAddress2,
                      billingCity: this.state.billingCity,
                      billingState: this.state.billingState,
                      billingZip: this.state.billingZip,
                      billingNotes: this.state.billingNotes,
                      useCardForRemainder: this.state.useCardForRemainder,
                      discountCode: this.state.discountCode || "",
                    }}
                    validate={(values) => {
                      this.setState({
                        billingFirstName: values.billingFirstName,
                        billingLastName: values.billingLastName,
                        billingAddress: values.billingAddress,
                        billingAddress2: values.billingAddress2,
                        billingCity: values.billingCity,
                        billingState: values.billingState,
                        billingZip: values.billingZip,
                      });

                      let errors = {};
                      if (!values.billingFirstName) {
                        errors.billingFirstName = "Required";
                      }
                      if (!values.billingLastName) {
                        errors.billingLastName = "Required";
                      }
                      if (!values.billingAddress) {
                        errors.billingAddress = "Required";
                      }
                      if (!values.billingCity) {
                        errors.billingCity = "Required";
                      }
                      if (!values.billingState) {
                        errors.billingState = "Required";
                      }
                      if (!values.billingZip) {
                        errors.billingZip = "Required";
                      }
                      if (values.discount) {
                        cart.setDiscount(values.discount);
                      }

                      cart.setCheckoutInfo({
                        ...cart.checkoutInfo,
                        referrer: sessionStorage.getItem("bpd-referrer"),
                        firstPage: sessionStorage.getItem("bpd-first-page"),
                        gclid: localStorage.getItem("bpd-gclid"),
                        twclid: localStorage.getItem("bpd-twclid"),
                        fbclid: localStorage.getItem("bpd-fbclid"),
                        utm_source: localStorage.getItem("bpd-utm_source"),
                        utm_medium: localStorage.getItem("bpd-utm_medium"),
                        utm_campaign: localStorage.getItem("bpd-utm_campaign"),
                        msclkid: localStorage.getItem("bpd-msclkid"),
                        marketingCampaignInfo: localStorage.getItem(
                          "marketingCampaignInfo"
                        ),
                        taxRate: process.env.TAX_RATE,
                        discountInformation:
                          sessionStorage.getItem("discounts"),
                        ...values,
                      });

                      return errors;
                    }}
                    onSubmit={(values, actions) => {
                      posthog.capture("user_submit_order_attempted", {
                        firstName: cart.checkoutInfo.firstName,
                        lastName: cart.checkoutInfo.lastName,
                        email: cart.checkoutInfo.email,
                        phone: cart.checkoutInfo.phone,
                        cartContents: cart.contents,
                        cartTotals: cart.totals,
                        discounts: cart.discounts,
                      });
                      cart.setCheckoutInfo({
                        ...cart.checkoutInfo,
                        ...values,
                      });

                      window.grecaptcha.ready(() => {
                        window.grecaptcha
                          .execute(process.env.RECAPTCHA_SITE_KEY, {
                            action: "checkout",
                          })
                          .then(async (token) => {
                            const cookies =
                              typeof document !== "undefined"
                                ? document.cookie
                                : [];

                            const serverData = {
                              ...cart.checkoutInfo,
                              dataValue: this.state.transactionDetails,
                              amount: cart.totals.dueNow.substring(1),
                              contents: cart.contents,
                              totals: cart.totals,
                              discount: cart.discounts,
                              callInfo: cart.checkoutInfo.callInfo
                                ? JSON.parse(cart.checkoutInfo.callInfo)
                                : {},
                              cookies,
                              recaptchaToken: token,
                              referralId: sessionStorage.getItem("referralId"),
                            };
                            serverData.cardLastFour =
                              this.state.transactionDetails.card.number.substring(
                                12
                              );

                            if (process.env.NODE_ENV === "development") {
                              // Simulate a successful response for development
                              actions.setSubmitting(false);
                              navigate(
                                `/checkout-success?email=${encodeURIComponent(
                                  cart.checkoutInfo.email
                                )}&phone=${encodeURIComponent(
                                  cart.checkoutInfo.phone
                                )}`,
                                {
                                  state: {
                                    transactionData: {}, // Fill with any mocked data you need
                                    amountCharged: cart.totals.dueNow,
                                    tax: cart.totals.tax,
                                    cartContents: cart.contents,
                                    cartTotals: cart.totals,
                                    checkoutInfo: cart.checkoutInfo,
                                    email: cart.checkoutInfo.email,
                                    callInfo: cart.checkoutInfo.callInfo
                                      ? JSON.parse(cart.checkoutInfo.callInfo)
                                      : {},
                                    discount: cart.discountCode,
                                  },
                                }
                              );
                              cart.resetCart();
                            } else {
                              // Make actual call in production
                              axios
                                .post(
                                  process.env.PAYMENT_CAPTURE_URL,
                                  serverData
                                )
                                .then((chargeResponse) => {
                                  actions.setSubmitting(false);
                                  if (
                                    chargeResponse.data.success ||
                                    chargeResponse.data.data.responsetext ===
                                      "Approved"
                                  ) {
                                    navigate(
                                      `/checkout-success?email=${encodeURIComponent(
                                        cart.checkoutInfo.email
                                      )}&phone=${encodeURIComponent(
                                        cart.checkoutInfo.phone
                                      )}/`,
                                      {
                                        state: {
                                          transactionData:
                                            chargeResponse.data.data,
                                          amountCharged: cart.totals.dueNow,
                                          tax: cart.totals.tax,
                                          cartContents: cart.contents,
                                          cartTotals: cart.totals,
                                          checkoutInfo: cart.checkoutInfo,
                                          email: cart.checkoutInfo.email,
                                          callInfo: cart.checkoutInfo.callInfo
                                            ? JSON.parse(
                                                cart.checkoutInfo.callInfo
                                              )
                                            : {},
                                          discount: cart.discountCode,
                                        },
                                      }
                                    );
                                    cart.resetCart();
                                  } else {
                                    alert(
                                      chargeResponse.data.data.responsetext
                                    );
                                  }
                                })
                                .catch((error) => {
                                  alert(
                                    `We are having an issue contacting our payment provider. Please call us to place your order. ${process.env.PHONE_NUMBER}`
                                  );
                                  actions.setSubmitting(false);
                                });
                            }
                          });
                      });
                    }}
                    enableReinitialize={true}
                  >
                    {({
                      values,
                      setFieldValue,
                      handleSubmit,
                      isSubmitting,
                      setSubmitting,
                    }) => {
                      this.handleSubmit = handleSubmit;
                      this.setSubmitting = setSubmitting;
                      return (
                        <Form id="checkoutStep3Form">
                          <div
                            className={
                              isSubmitting ? "submitting-payment" : "hidden"
                            }
                          >
                            Securely processing order details...
                          </div>
                          <div
                            className={
                              this.state.useDifferentAddress ? "hidden" : "mb-4"
                            }
                          >
                            <h4 className="text-grey-darkest">
                              {this.state.billingFirstName}{" "}
                              {this.state.billingLastName}
                            </h4>
                            <p className="text-grey-darkest">
                              {this.state.billingAddress}
                              <br />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: this.state.billingAddress2
                                    ? `${this.state.billingAddress2}<br />`
                                    : "",
                                }}
                              />
                              {this.state.billingCity},{" "}
                              {this.state.billingState} {this.state.billingZip}
                            </p>
                          </div>
                          <div>
                            <div className="flex flex-wrap w-full items-stretch">
                              <div className="w-full mb-6">
                                <label
                                  htmlFor="useDifferentBillingAddress"
                                  className="block mb-2"
                                >
                                  <Field
                                    type="checkbox"
                                    name="useDifferentBillingAddress"
                                    id="useDifferentBillingAddress"
                                    placeholder=""
                                    className="mr-2 px-12 focus:outline-none focus:shadow"
                                    onClick={this.toggleAddressType}
                                    checked={this.state.useDifferentAddress}
                                  />
                                  <strong>
                                    Use a different billing address
                                  </strong>{" "}
                                </label>
                              </div>
                            </div>
                            <div
                              className={`${
                                !this.state.useDifferentAddress
                                  ? "hidden"
                                  : "flex flex-wrap"
                              } w-full items-stretch`}
                            >
                              <div className="mb-6 w-1/2 pr-2">
                                <label
                                  htmlFor="billingFirstName"
                                  className="block mb-2"
                                >
                                  <strong>First Name</strong>{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="billingFirstName"
                                  id="billingFirstName"
                                  placeholder="First Name"
                                  className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                                />
                                <ErrorMessage
                                  className="text-red-dark text-sm mt-2"
                                  name="billingFirstName"
                                  component="div"
                                />
                              </div>
                              <div className="mb-6 w-1/2 pr-2">
                                <label
                                  htmlFor="billingLastName"
                                  className="block mb-2"
                                >
                                  <strong>Last Name</strong>{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="billingLastName"
                                  id="billingLastName"
                                  placeholder="Last Name"
                                  className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                                />
                                <ErrorMessage
                                  className="text-red-dark text-sm mt-2"
                                  name="billingLastName"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>

                          <div
                            className={
                              !this.state.useDifferentAddress
                                ? "hidden"
                                : "mb-16"
                            }
                          >
                            <h3 className="mt-12 mb-4 pb-2 border-b border-grey-light">
                              Billing Address
                            </h3>

                            <Field
                              type="text"
                              name="billingAddress"
                              placeholder="Billing Address"
                              className="shadow appearance-none border border-grey-light rounded w-full mb-4 py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                            />
                            <ErrorMessage
                              className="text-red-dark text-sm mt-2"
                              name="billingAddress"
                              component="div"
                            />

                            <div className="mb-6">
                              <Field
                                type="text"
                                name="billingAddress2"
                                placeholder="Line 2"
                                className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                              />
                              <ErrorMessage
                                className="text-red-dark text-sm mt-2"
                                name="billingAddress2"
                                component="div"
                              />
                            </div>

                            <div className="flex flex-wrap w-full items-stretch">
                              <div className="mb-6 w-1/2 pr-2">
                                <label
                                  htmlFor="billingCity"
                                  className="block mb-2"
                                >
                                  <strong>City</strong>{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="billingCity"
                                  placeholder="City"
                                  className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                                />
                                <ErrorMessage
                                  className="text-red-dark text-sm mt-2"
                                  name="billingCity"
                                  component="div"
                                />
                              </div>

                              <div className="mb-6 w-1/2 pl-2">
                                <label
                                  htmlFor="billingState"
                                  className="block mb-2"
                                >
                                  <strong>State</strong>{" "}
                                </label>
                                <Field
                                  component="select"
                                  name="billingState"
                                  className="bg-white shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                                >
                                  <option
                                    value=""
                                    key="na"
                                    aria-label="Not Applicable"
                                  />
                                  {states.map((state, index) => {
                                    return (
                                      <option
                                        value={state.abbreviation}
                                        key={index}
                                      >
                                        {state.name}
                                      </option>
                                    );
                                  })}
                                </Field>
                                <ErrorMessage
                                  className="text-red-dark text-sm mt-2"
                                  name="billingState"
                                  component="div"
                                />
                              </div>
                            </div>

                            <div className="mb-6">
                              <div className="mb-6 w-1/2 pr-2">
                                <label
                                  htmlFor="billingZip"
                                  className="block mb-2"
                                >
                                  <strong>Zip</strong>{" "}
                                </label>
                                <Field
                                  type="text"
                                  name="billingZip"
                                  placeholder="84000"
                                  className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                                />
                                <ErrorMessage
                                  className="text-red-dark text-sm mt-2"
                                  name="billingZip"
                                  component="div"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap items-end">
                            <div className="mb-6 w-1/2 pr-2">
                              <label
                                htmlFor="discountCode"
                                className="block mb-2"
                              >
                                <strong>Discount Code</strong>
                              </label>
                              <div className="relative">
                                <Field
                                  type="text"
                                  name="discountCode"
                                  placeholder=""
                                  className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      event.preventDefault();
                                      cart.setDiscountCode(values.discountCode);
                                    }
                                  }}
                                />
                                {values.discountCode && (
                                  <button
                                    className="absolute p-1 text-red"
                                    onClick={() => {
                                      setFieldValue("discountCode", "");
                                      cart.setDiscountCode("");
                                    }}
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      right: "6px",
                                      top: "6px",
                                    }}
                                    type="button"
                                  >
                                    x
                                  </button>
                                )}
                              </div>
                              <ErrorMessage
                                className="text-red-dark text-sm mt-2"
                                name="discountCode"
                                component="div"
                              />
                            </div>
                            {cart.discounts?.code !== values.discountCode ? (
                              <div className="mb-6 w-1/2 pr-2">
                                {values.discountCode && (
                                  <button
                                    className={`block md:inline-block bg-blue text-white text-sm px-4 py-3 no-underline rounded uppercase ${
                                      !values.discountCode ? "opacity-50" : ""
                                    }`}
                                    type="button"
                                    disabled={!values.discountCode}
                                    onClick={() => {
                                      cart.setDiscountCode(values.discountCode);
                                    }}
                                  >
                                    Apply
                                  </button>
                                )}
                              </div>
                            ) : (
                              <div className="mb-6 w-1/2 pr-2 pb-3 text-sm">
                                Code{" "}
                                <code className="py-1 px-2 bg-grey-lightest border border-grey-light rounded">
                                  {cart.discounts?.code}
                                </code>{" "}
                                applied
                              </div>
                            )}
                          </div>

                          <div className="bg-grey-lighter p-4 mb-4">
                            <div className="flex mb-4 mt-4 pb-2 border-b border-grey-light items-end justify-between">
                              <div className="flex justify-start">
                                <h3>Credit Card</h3>
                                <svg
                                  className="ml-1 lg:ml-3 heroicon-lock heroicon heroicon-sm"
                                  height="17"
                                  onDoubleClick={() =>
                                    this.setState({
                                      showDebugInfo: !this.state.showDebugInfo,
                                    })
                                  }
                                  viewBox="0 0 60 60"
                                  width="17"
                                  xmlns="https://www.w3.org/2000/svg"
                                >
                                  <rect
                                    className="heroicon-lock-body heroicon-component-fill"
                                    width="54"
                                    height="34"
                                    x="3"
                                    y="25"
                                  />
                                  <path
                                    className="heroicon-lock-top heroicon-component-accent heroicon-component-fill"
                                    d="M12 25v-6a18 18 0 1 1 36 0v6h9v5H3v-5h9zm6 0h24v-6a12 12 0 1 0-24 0v6z"
                                  />
                                  <path
                                    className="heroicon-shadows"
                                    d="M45 25v-6a15 15 0 0 0-30 0v6h3v-6a12 12 0 1 1 24 0v6.33l3-.33z"
                                  />
                                  <path
                                    className="heroicon-outline"
                                    fillRule="nonzero"
                                    d="M11 24v-5a19 19 0 0 1 38 0v5h9v36H2V24h9zm0 2H4v3h25v-3H11zm18 5H4v3h25v-3zM4 38h25v-3H4v3zm0 1v3h25v-3H4zm0 7h25v-3H4v3zm0 1v3h25v-3H4zm0 7h25v-3H4v3zm0 1v3h25v-3H4zm27 0v3h25v-3H31zm25-1v-3H31v3h25zm0-7H31v3h25v-3zm0-1v-3H31v3h25zm0-7H31v3h25v-3zm0-1v-3H31v3h25zm0-7H31v3h25v-3zm0-2v-3H31v3h25zm-15-5v-5a11 11 0 0 0-22 0v5h22zm2 0h4v-5a17 17 0 1 0-34 0v5h4v-5a13 13 0 0 1 26 0v5z"
                                  />
                                </svg>
                              </div>
                              <CreditCardLogos />
                            </div>

                            <div className="mb-6">
                              <label htmlFor="ccnumber" className="block mb-2">
                                <strong>Credit Card Number</strong>{" "}
                              </label>
                              <div
                                className="paymentField bg-white shadow appearance-none border border-grey-light rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                                id="ccnumber"
                              ></div>
                            </div>

                            <div className="flex flex-wrap w-full items-stretch">
                              <div className="mb-6 w-1/2 pr-2">
                                <label htmlFor="ccexp" className="block mb-2">
                                  <strong>Expiration</strong>{" "}
                                </label>
                                <div
                                  className="paymentField bg-white shadow appearance-none border border-grey-light rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                                  id="ccexp"
                                ></div>
                              </div>

                              <div className="mb-6 w-1/2 pl-2">
                                <label htmlFor="cvv" className="block mb-2">
                                  <strong>CVC Number</strong>{" "}
                                </label>
                                <div
                                  className="paymentField bg-white shadow appearance-none border border-grey-light rounded w-full text-grey-darker leading-tight focus:outline-none focus:ring text-base"
                                  id="cvv"
                                ></div>
                              </div>
                            </div>

                            {/* <div>
                              <Field
                                component={Checkbox}
                                name="useCardForRemainder"
                                id="useCardForRemainder"
                                label="Use this card for the remainder"
                                className="block"
                                checked={values.useCardForRemainder}
                              />
                            </div> */}
                          </div>
                          <div className="my-6">
                            <label
                              htmlFor="billingNotes"
                              className="block mb-2"
                            >
                              <strong>Billing Notes</strong>{" "}
                            </label>
                            <Field
                              component="textarea"
                              type="textarea"
                              name="billingNotes"
                              className="shadow appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-32"
                            />
                            <ErrorMessage
                              className="text-red-dark text-sm mt-2"
                              name="billingNotes"
                              component="div"
                            />
                          </div>
                          <div className="mb-4">
                            <h3 className="text-lg mb-2">Payment Schedule</h3>
                            <ol className="mb-4">
                              <li className="text-xs">
                                <span className="underline">
                                  {" "}
                                  <SupDollarSign />
                                  100 Deposit:
                                </span>{" "}
                                To initiate the process, only a{" "}
                                <SupDollarSign />
                                100 deposit is required. This deposit is
                                entirely refundable until we visit your home to
                                measure your window wells.
                              </li>
                              <li className="text-xs">
                                <span className="underline">
                                  50% Balance Due:{" "}
                                </span>{" "}
                                After we’ve measured your window wells and
                                discussed your specific requirements, a 50%
                                payment of the remaining total balance will be
                                charged. This step ensures that your customized
                                window well covers are on their way to being
                                crafted with precision.
                              </li>
                              <li className="text-xs">
                                <span className="underline">
                                  {" "}
                                  Completion and Final Payment:
                                </span>{" "}
                                Once we return to your home to install the
                                window well covers, the remaining balance will
                                be charged.
                              </li>
                            </ol>

                            <div className="w-full mb-6">
                              <label
                                htmlFor="agreeToTerms"
                                className="block mb-2"
                              >
                                <Field
                                  type="checkbox"
                                  name="agreeToTerms"
                                  id="agreeToTerms"
                                  placeholder=""
                                  className="mr-2 px-12 focus:outline-none focus:shadow"
                                  onClick={(e) => {
                                    this.setState({
                                      agreeToTerms: e.target.checked,
                                    });
                                  }}
                                  checked={this.state.agreeToTerms}
                                />
                                <strong>I agree to the Payment Schedule</strong>{" "}
                              </label>
                            </div>
                          </div>

                          <div className="mb-6 flex justify-between">
                            <Link
                              to="/checkout-step-2/"
                              className="block md:inline-block bg-grey text-white text-sm px-4 py-3 no-underline rounded uppercase"
                            >
                              Previous Step
                            </Link>
                            <button
                              id="payButton"
                              type="submit"
                              title={
                                this.canSubmit(isSubmitting)
                                  ? "Submit my payment"
                                  : "You are missing at least one required field"
                              }
                              onClick={(e) => {
                                setSubmitting(true);
                              }}
                              className={`${
                                !this.canSubmit(isSubmitting)
                                  ? "hidden"
                                  : "block md:inline-block"
                              } bg-${
                                process.env.THEME_COLOR_PRIMARY
                              } text-white text-sm px-4 py-3 no-underline rounded uppercase`}
                            >
                              {isSubmitting
                                ? "Placing order"
                                : "Submit Payment"}
                            </button>
                            <button
                              type="button"
                              title="You are missing at least one required field"
                              onClick={(e) => {
                                e.preventDefault();
                                alert(
                                  "Please fill out all of the required fields"
                                );
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                                alert(
                                  "Please fill out all of the required fields"
                                );
                              }}
                              className={`${
                                this.canSubmit(isSubmitting)
                                  ? "hidden"
                                  : "block md:inline-block"
                              } opacity-50 bg-${
                                process.env.THEME_COLOR_PRIMARY
                              } text-white text-sm px-4 py-3 no-underline rounded uppercase`}
                            >
                              Submit Payment
                            </button>
                          </div>
                          {this.state.showDebugInfo && (
                            <div>
                              <p>
                                ccnumberValid:{" "}
                                {this.state.ccnumberValid ? "yes" : "no"}
                              </p>
                              <p>
                                ccexpValid:{" "}
                                {this.state.ccexpValid ? "yes" : "no"}
                              </p>
                              <p>
                                cvvValid: {this.state.cvvValid ? "yes" : "no"}
                              </p>
                              <p>
                                addressValid:{" "}
                                {this.checkAddress(false) ? "yes" : "no"}
                              </p>
                            </div>
                          )}
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
                <div className="w-full md:w-2/5 lg:pl-6">
                  <CartWidget hideButtons={true} hideYouMayAlsoLike={true} />
                  <CartTotals />
                </div>
              </div>
            </div>
          </>
        )}
      </CartContext.Consumer>
    );
  }
}

const CheckoutEvent = () => {
  const { onStartCheckout } = useContext(CartContext);
  useEffect(() => {
    onStartCheckout(3);
  });

  return null;
};
